<template>
    <div class="login" :style="`background-image: url(${loginBg})`">
        <div class="login_content">
            <div class="title">助教端登陆</div>
            <el-form ref="formData" :model="formData" :rules="rules">
                <el-form-item prop="telNo">
                    <el-input
                        size="medium"
                        v-model.trim="formData.telNo"
                        :maxlength="11"
                        placeholder="请输入账号"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input size="medium" placeholder="请输入密码" show-password v-model="formData.password" />
                </el-form-item>
                <el-form-item>
                    <el-button class="login_btn" size="medium" type="primary" @click="onLogin">登 录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import _config from '@/js/config.js'
import { staffLogin } from '@/js/api/staffApi.js'
import { getTalkLiveurl } from '@/js/api/courseApi.js'
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            loginBg: _config.loginBg,
            formData: { telNo: '', password: '' },
            rules: {
                telNo: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
        }
    },
    created() {
        document.onkeydown = (event) => {
            if (event.key === 'Enter') {
                this.onLogin()
            }
        }

        this.$store.dispatch('global/storage', { key: 'loginData' }).then((res) => {
            const _data = res ? JSON.parse(res) : {}
            this.formData.telNo = _data.telNo
            this.formData.password = _data.password
        })
        if (this.userInfo) {
            this.getTalkLiveurlApi()
        }
    },
    destroyed() {
        document.onkeydown = null
    },
    methods: {
        onLogin() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    staffLogin({ type: 'TEL_PASSWORD', ...this.formData }).then((res) => {
                        document.onkeydown = undefined
                        this.$store.dispatch('user/login', { ...res.data }).then(() => {
                            this.getTalkLiveurlApi()
                        })
                        this.$store.dispatch('global/storage', {
                            key: 'loginData',
                            val: JSON.stringify(this.formData),
                        })
                    })
                }
            })
        },
        getTalkLiveurlApi() {
            if (!this.$route.query.courseNo) {
                return this.$message.warning('暂无课程')
            }
            getTalkLiveurl({
                staffId: this.userInfo.id,
                courseNo: this.$route.query.courseNo,
            }).then((res) => {
                window.location.replace(res.data.url)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .login_content {
        z-index: 3;
        width: 480px;
        background: #ffffff;
        border-radius: 12px;
        padding: 0 24px;
        box-sizing: border-box;
        box-shadow: rgba(100, 100, 111, 1) 0px 7px 29px 0px;
        .title {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin: 32px auto;
        }
        /deep/ .el-input {
            .el-input__inner {
                height: 42px;
                line-height: 42px;
            }
        }
        .el-form-item {
            margin-bottom: 20px;
        }
        .login_btn {
            width: 90%;
            height: 48px;
            font-size: 18px;
            margin: 24px auto 12px;
            display: block;
        }
    }
}

@media screen and (max-width: 800px) {
    .login {
        .login_content {
            width: 90%;
            height: auto;
            border-radius: 6px;
            /deep/ .el-input {
                .el-input__inner {
                    height: 42px;
                    line-height: 42px;
                }
            }
            .logo {
                width: 120px;
            }
            .el-form-item {
                margin-bottom: 20px;
            }
            .login_btn {
                width: 90%;
                height: 42px;
                font-size: 18px;
                margin: 18px auto 12px;
                display: block;
            }
        }
    }
}
</style>
